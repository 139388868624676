#root {
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNovaT-Thin.woff') format('woff'),
    url('./fonts/ProximaNovaT-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNova-Light.woff') format('woff'),
    url('./fonts/ProximaNova-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNova-Regular.woff') format('woff'),
    url('./fonts/ProximaNova-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNova-Semibold.woff') format('woff'),
    url('./fonts/ProximaNova-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNova-Bold.woff') format('woff'),
    url('./fonts/ProximaNova-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNova-Extrabld.woff') format('woff'),
    url('./fonts/ProximaNova-Extrabld.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/ProximaNova-Black.woff') format('woff'),
    url('./fonts/ProximaNova-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
}
